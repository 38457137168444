import React from 'react'
import { CheckMark } from '../../01_Homepage/Features/ProductSection'
import { useNavigate } from 'react-router-dom';

const ForConsultantsHeader = ({inOverviewPage}) => {
    const navigate = useNavigate();
  return (
    <div className={`lg:flex items-center lg:space-x-16 px-16 py-16 px-4`}>
          
          <div className='w-full lg:hidden mb-16'>
            <img src="/img/product_pictures/screens_selectionportal/consultant_1.png" alt="logo" className="cursor-pointer transition-all duration-500 hover:scale-105 " />
        </div>

        <div className='lg:w-4/12 '>
            {inOverviewPage ? <p className='text-gray-800 text-sm uppercase font-semibold'>For consultants</p> : <></>}
            <h2 className='lg:text-4xl text-2xl font-bold mb-8'>Find suitable customers and accelerate projects</h2>
            <p className='mb-4 text-gray-700'>On find-your-SOFTWARE's consultant marketplace, you can list your strengths and expertise for free so that we can lead clients to you who are a real fit. Use find-your-SOFTWARE's tools for your own projects to avoid repetitive tasks and reach your goals faster and more reliably.</p>
            
            <ul class="space-y-4 text-left font-semibold text-gray-700">
                <li class="flex items-center space-x-3 rtl:space-x-reverse">
                    <CheckMark />
                    <span>Showcase your expertise and industry focus</span>
                </li>
                <li class="flex items-center space-x-3 rtl:space-x-reverse">
                    <CheckMark />
                    <span>Visibility on the market and with niche customers</span>
                </li>
                <li class="flex items-center space-x-3 rtl:space-x-reverse">
                    <CheckMark />
                    <span>Use the find-your-SOFTWARE AI market analyses and collaborative selection tools for your projects </span>
                </li>
            </ul>

            {inOverviewPage ? 
                <button  onClick={() => navigate("/solution/for-consultants")} className="mt-8 h-16 text-base font-medium text-white items-center group transition-all focus:ring-4 focus:outline-none rounded-lg text-center" type="button" >
                <div className="flex w-full text-lg font-semibold text-primary group-hover:text-primary3 transition-all">
                    <p className='underline underline-offset-4 group-hover:underline-offset-8 transition-all'>Learn more</p>
                    <p className='translate-x-2 group-hover:translate-x-4 transition-all'>→</p>
                </div>
                </button>
             : <></>}

        </div>

        <div className='lg:w-7/12 w-full max-md:hidden lg:p-8 mb-16'>
            <img src="/img/product_pictures/screens_selectionportal/consultant_1.png" alt="logo" className="cursor-pointer transition-all duration-500 hover:scale-105 " />
        </div>
    </div>
  )
}

export default ForConsultantsHeader