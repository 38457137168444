import React from 'react'
import { useNavigate } from 'react-router-dom'

const JobsSection = () => {
  const navigate = useNavigate();
  return (
    <section className="mb-8 lg:w-1/2">
        <h2 id="open-positions" className="text-4xl text-primary font-semibold">Open positions</h2>
        <p className="mt-2 text-gray-700">
          We love to experiment with new technologies, algorithms and methodologies to develop innovative solutions for selecting software. Our enthusiasm for working with cutting-edge tools and dedication to continuous improvement make us pioneers in this industry. If you want to live out your passion for product development, software engineering and design in an environment that focuses on innovation, team spirit, experimentation, and personal development, <span className="logo_text">find-your-SOFTWARE</span> is the ideal place for you to grow.
        </p>

        <h3 className="mt-8 mb-2 text-gray-700 text-lg font-semibold">What we offer</h3>
        <ul className="mb-2 space-y-1 text-gray-700 list-disc list-inside dk:text-gray-400">
          <li>An opportunity to be part of a promising startup from the beginning and have a significant impact on its success.</li>
          <li>Take responsibility and manage your own areas independently.</li>
          <li>A dynamic and creative environment where ideas and suggestions are actively encouraged.</li>
          <li>An attractive compensation package and additional benefits.</li>
          <li>Professional development opportunities and regular team events.</li>
        </ul>

        <button  onClick={() => navigate("/jobs")} className="mt-8 h-16 text-base font-medium text-white items-center group transition-all focus:ring-4 focus:outline-none rounded-lg text-center" type="button" >
          <div className="flex w-full text-lg font-semibold text-primary group-hover:text-primary3 transition-all">
            <p className='underline underline-offset-4 group-hover:underline-offset-8 transition-all'>Join us</p>
            <p className='translate-x-2 group-hover:translate-x-4 transition-all'>→</p>
          </div>
        </button>
        
      </section>
  )
}

export default JobsSection