import React from 'react'
import RepetitionCard from './Components/RepetitionCard'
import BookADemo from '../../components/BookADemo';
import ForVendorsHeader from './Features/ForVendorsHeader';
import ForConsultantsHeader from './Features/ForConsultantsHeader';
import ForSeekersHeader from './Features/ForSeekersHeader';

const SolutionOverview = () => {
  return (
    <div className='mt-16 pb-16 mb-32'>
        <div className='w-full flex flex-col items-center mb-16 px-4'>
          <h1 className='text-4xl text-center font-bold'>Solutions for everyone</h1>
          <p className='text-md text-center text-gray-400 w-2/3'>find-your-SOFTWARE offers solutions for all those involved in selecting business software - whether you are looking for software, offering software or are a selection consultant. find-your-SOFTWARE offers suitable tools for everyone and facilitates collaboration.</p>
        </div>
      
      {/* <div className='md:w-[1000px] mx-auto '>
          <div className="flex mt-8 mb-4 items-center justify-center mx-auto max-lg:flex-col max-lg:w-2/3">
              <RepetitionCard title="Seeker" color="text-primary" text="Software selection processes are inherently complex, especially considering that only 20% of German companies have dedicated IT employees. Misjudged software choices incur significant costs for German SMEs, estimated at around 4 million Euros annually." />
              <RepetitionCard title="Vendor" color="text-primary" text="find-your-SOFTWARE gives you visibility in the market by listing you for free and matching you with potential customers. By analyzing potential customers with AI, we can suggest sales leads that really suit you and are enriched with high-quality details so that you can maximize your conversion success." />
              <RepetitionCard title="Consultant" color="text-primary" text="In the competitive world of sales, securing quality leads is essential for success. With aisle, harnessing the power of AI, we redefine lead generation. By leveraging advanced algorithms, Aisle identifies and cultivates promising leads, ensuring businesses capitalize on every opportunity." />
          </div>
      </div> */}

      <ForSeekersHeader inOverviewPage={true} />
      <ForVendorsHeader inOverviewPage={true} />
      <ForConsultantsHeader inOverviewPage={true} />

      <div className='w-full h-fit lg:px-64 mt-32 px-4'>
          <div className='border rounded-[30px] bg-opacity-90 shadow-lg p-8'>
              <BookADemo />
          </div>
      </div>
    </div>
  )
}

export default SolutionOverview