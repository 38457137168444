import React from 'react'
import { useNavigate } from 'react-router-dom'

const TeamSection = () => {
  const navigate = useNavigate();

  return (
    <section className="pb-16 lg:w-1/2">
        <h2 id="about-us" className="text-4xl text-primary font-semibold">About us</h2>
        <p className="mt-2 text-gray-700">
          <span className="logo_text">find-your-SOFTWARE</span> combines a passion for research, coding and consulting. Thus, methodical expertise and market insights regarding software selection as well as expertise in the field of digital platforms, software engineering and artificial intelligence are integrated seamlessly. Innovative experiments and dynamic interactions shape the collaboration and lead to productivity and high-quality results.
        </p>

        <p className="mt-2 text-gray-700">
          <span className="logo_text">find-your-SOFTWARE</span> plans to grow continuously and is consequently always on the lookout for skilled team members. If you feel like working in a dynamic startup and having a real impact, feel free to check out the <a href="#open-positions" className="underline underline-offset-2 hover:underline-offset-4">open positions</a> or <a href="mailto:hi@sectorlens.de" className="underline underline-offset-2 hover:underline-offset-4">contact us</a> proactively. 
        </p>

        <button  onClick={() => navigate("/about-us")} className="mt-8 h-16 text-base font-medium text-white items-center group transition-all focus:ring-4 focus:outline-none rounded-lg text-center" type="button" >
          <div className="flex w-full text-lg font-semibold text-primary group-hover:text-primary3 transition-all">
            <p className='underline underline-offset-4 group-hover:underline-offset-8 transition-all'>Get to know the team</p>
            <p className='translate-x-2 group-hover:translate-x-4 transition-all'>→</p>
          </div>
        </button>
      </section>
  )
}

export default TeamSection