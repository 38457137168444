import React, { useEffect, useState } from 'react'
import NewsletterModal from '../../../components/NewsletterModal';
import SurveyModal from '../../../components/SurveyModal';
import useTypewriter from '../../../hooks/useTypewriter';
import BookADemoModal from '../../../components/BookADemoModal';


const HeroSection = () => {
  const [ bookADemoModalOpen, setBookADemoModalOpen ] = useState(false);
  const [ newsletterModalOpen, setNewsletterModalOpen ] = useState(false);
  const [ surveyModalOpen, setSurveyModalOpen ] = useState(false);
  const [ visibleText, typingFinished ] = useTypewriter(["ERP", "CRM", "HR", "BI", "DWH", "MES", "WMS", "MDM", "PM", "PLM", "CAD", "CAQ", "CMS"], 100);

  return (
    <section className="bg-no-repeat bg-contain bg-left-bottom relative" style={{backgroundImage: "url(img/hero-bg.svg)"}}>
      <div className="max-w-1200 mx-auto px-6 max-lg:px-5 max-md:p-0  ">
          <div className="flex justify-between pt-40 pb-[262px] max-xl:pt-32 max-xl:pb-52 max-lg:pt-20 max-lg:flex-col max-lg:pb-20 max-md:pb-14 max-sm:pt-12 max-sm:gap-6 max-sm:pb-0">
              
              <div className="md:ml-32 max-w-[800px] relative z-40 max-xl:max-w-[540px] max-lg:max-w-xl max-md:px-5 max-sm:px-4">
                  <h1 className="text-5xl max-md:text-3xl font-semibold text-secondary1 mt-2 leading-58 max-xl:text-[44px] max-xl:leading-[48px] max-sm:text-40">
                    <span className="logo_text">find-your-SOFTWARE</span> simplifies your <span id="demo" className="typewriter">{visibleText}</span> selection
                  </h1>
                  <p className="text-secondary2 font-normal max-w-[600px] my-6 max-xl:max-w-[480px] max-lg:max-w-lg">
                      Welcome to <span className="logo_text">find-your-SOFTWARE</span>, the pioneer in the world of software selection! We help companies find the right enterprise software by automating and simplifying the selection process. <span className="logo_text">find-your-SOFTWARE</span> relies on modern and exciting technologies (e.g. LLMs, NLP/NLG, web crawling) and provides an innovative and user-friendly platform for all stakeholders to share and collaborate.
                  </p>
                  <button onClick={() => setBookADemoModalOpen(true)} className="text-base font-medium text-white inline-flex items-center bg-[#f7ce46] hover:scale-110 transition-all focus:ring-4 focus:outline-none rounded-lg text-center" type="button">
                    <div className="w-full text-2xl font-semibold text-primary p-2 sm:p-4 dk:bg-gray-800 dk:border-gray-700">
                    Start now!
                    </div>
                  </button>

                  <div className="flex flex-wrap gap-4 items-center mt-6">
                    <img src="/img/funding_logos/logo_bmwi.png" alt="bmwi" className="h-32 sm:h-32" />
                    <img src="/img/funding_logos/logo_exist.svg" alt="exist" className="h-6 sm:h-10" />
                    <img src="/img/funding_logos/logo_pt.png" alt="pt" className="h-6 sm:h-10" />
                    <img src="/img/funding_logos/logo_up.png" alt="up" className="h-8 sm:h-12" />
                    <img src="/img/funding_logos/logo_sib.png" alt="sib" className="h-5 sm:h-8" />
                    <img src="/img/funding_logos/logo_hwr.png" alt="hwr" className="h-5 sm:h-8" />
                    <img src="/img/funding_logos/logo_berlin.png" alt="berlin" className="h-6 sm:h-10" />
                    <img src="/img/funding_logos/logo_eu2.jpg" alt="eu" className="h-6 sm:h-10" />

                  </div>

              </div>

              <div className="absolute right-0 -top-4 max-xl:max-w-2xl max-lg:relative max-lg:ml-auto max-lg:-top-0 max-lg:-mr-5 max-md:pl-[10px] max-md:-mr-0 max-sm:pb-14">
                  <img src="/img/hero-section-img.svg" alt="" className="w-full" />
              </div>
          </div>
      </div>
      
      <BookADemoModal isOpen={bookADemoModalOpen} setOpen={setBookADemoModalOpen} />
      <NewsletterModal isOpen={newsletterModalOpen} setOpen={setNewsletterModalOpen} setSurveyModalOpen={setSurveyModalOpen} />
      <SurveyModal isOpen={surveyModalOpen} setOpen={setSurveyModalOpen} />
    </section>
  )
}

export default HeroSection