import React from 'react'

const BaseModal = ({children, isOpen, setOpen}) => {
    if (!isOpen) return <></>;
    return (
        <div className="fixed flex justify-center items-center z-[50] p-4 inset-0 max-h-full overflow-x-hidden overflow-y-auto">
            <div className="relative z-[52] max-h-[100vh]">
                
                <div className="relative bg-white rounded-lg shadow">
                
                <div className="flex items-start justify-between p-4 border-b rounded-t">
                    <h3 className="text-xl font-semibold text-gray-900 dk:text-white">
                    Thank you for your interest in <span className="logo_text">find-your-SOFTWARE</span>.
                    </h3>

                    <button type="button" onClick={() => setOpen(false)} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center" data-modal-hide="defaultModal">
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>

                </div>
                
                <div className="p-6">
                    {children}
                </div>
                
                <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dk:border-gray-600">
                    <button onClick={() => setOpen(false)} type="button" className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dk:bg-gray-700 dk:text-gray-300 dk:border-gray-500 dk:hover:text-white dk:hover:bg-gray-600 dk:focus:ring-gray-600">Close</button>
                </div>

                </div>
            </div>

            <div onClick={() => setOpen(false)} className='fixed bg-gray-900 opacity-40 top-0 left-0 w-full h-full z-[51]'></div>
        </div>
    )
}

export default BaseModal