import React, { useCallback, useEffect, useRef, useState } from 'react';
import TiltBox from '../../../components/TiltBox';
import useTypewriter from '../../../hooks/useTypewriter';

const MatchingEngineFlow = () => {
    const [visibleText, typingFinished] = useTypewriter(["Enter your company URL...", "https://www.sectorlens.de", "https://www.adidas.de", "https://www.your-company-url.de", "https://www.example.com"], 100);
  
    return (
    <div className='relative max-md:w-full w-[1060px] my-32 lg:pt-32 max-md:scale-100 max-lg:scale-[60%] max-md:space-y-16 px-4'>

            <div className='flex justify-end lg:h-[406px]'>

                <div className='max-md:hidden mr-5 mt-5 text-primary2'>
                    <svg width="428" height="406" viewBox="0 0 428 406" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M424.08 7.5001C379.58 -6.33324 281.58 7.90009 245.58 175.5C200.58 385 36.0801 407.5 3.58008 401.5" stroke="currentColor" stroke-width="6" stroke-linecap="round" stroke-dasharray="12 12"/>
                    </svg>
                </div>

                <div className='flex flex-col lg:w-[530px]'>
                    <div className='w-12 h-12 flex items-center justify-center bg-primary rounded-full text-white text-2xl text-center font-bold'>1</div>
                    <div className='text-2xl font-bold mt-2'>Give us a starting point</div>
                    <div className='text-md mt-2'>Enter your company's URL. Our AI scans your website and other relevant data sources that we find publicly on the internet about your company to extract relevant software requirements, goals, and preferences.</div>
                </div>

                <div className='max-md:hidden absolute text-primary -left-[100px] -top-[50px]'>
                    <div className='relative w-[500px] h-[500px]'>
                        <svg className="w-full h-full opacity-10 absolute z-[0]" viewBox="0 0 326 311" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M242.877 10.3309C268.88 27.4086 266.876 65.6702 281.003 93.3604C293.753 118.35 316.545 137.474 321.802 165.023C327.781 196.353 329.465 231.29 312.481 258.301C295.351 285.545 263.28 300.638 231.913 307.981C203.259 314.689 174.084 306.287 146.077 297.256C121.312 289.27 101.8 272.994 79.8973 258.962C52.996 241.726 13.6397 235.434 2.7509 205.428C-8.05062 175.664 15.6044 145.002 27.9611 115.846C39.2557 89.1969 49.8675 61.9128 71.6608 42.837C93.8549 23.4102 122.115 13.5575 151.11 8.05256C181.792 2.22763 216.785 -6.80538 242.877 10.3309Z" fill="currentColor"/>
                        </svg>

                        <div className='absolute left-0 top-0 z-[1] w-[500px] h-[500px]'>
                            <TiltBox>
                                <img src="/img/product_pictures/tilt_matchingengine/matchingengine_page.png" alt="" style={{transform: "translateZ(0px)"}} className="absolute w-[70%]" />
                                {/* <img src="/img/tilt_matchingengine/matchingengine_searchbar.png" alt="" style={{transform: "translateZ(50px)"}} className="absolute w-[70%] top-[55%] left-[5%]" /> */}
                                <div style={{transform: "translateZ(50px)"}} className="absolute w-[90%] h-[5%] top-[55%] left-[0%]">

                                    <div className={`p-2 pl-0 shadow-lg border border-secondary6 rounded-lg flex items-center justify-between bg-white max-sm:items-start max-sm:items-start max-sm:p-2`}>
                                        <div id="searchbar" className="typewriter truncate bg-transparent outline-none border-none placeholder:text-secondary4 placeholder:text-sm font-normal px-6 w-4/5 max-sm:h-11 max-sm:w-full max-sm:p-0">{visibleText}</div>
                                        
                                        <div className={`relative flex ${typingFinished ? "bg-primary2 scale-[90%]" : "bg-primary"} text-white hover:shadow-primary rounded items-center justify-center gap-3 hover:shadow duration-300 transition-all ease-in-out max-sm:justify-center`}>
                                            <div className='flex gap-3 py-1 px-4 items-center'>
                                                Match
                                                <svg className={`w-[15px] h-[15px]`} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.58333 17.5C13.9556 17.5 17.5 13.9556 17.5 9.58333C17.5 5.21108 13.9556 1.66667 9.58333 1.66667C5.21108 1.66667 1.66666 5.21108 1.66666 9.58333C1.66666 13.9556 5.21108 17.5 9.58333 17.5Z" stroke={"white"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M18.3333 18.3333L16.6667 16.6667" stroke={"white"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </TiltBox>
                        </div>
                    </div>

                </div>
                
            </div>

            <div className='relative flex w-full justify-start lg:h-[316px]'>

                <div className='flex flex-col lg:w-[500px]'>
                    <div className='w-12 h-12 flex items-center justify-center bg-primary rounded-full text-white text-2xl text-center font-bold'>2</div>
                    <div className='text-2xl font-bold mt-2'>find-your-SOFTWARE analyzes your company</div>
                    <div className='text-md mt-2'>Our AI analyzes your requirements, goals and preferences and matches them to all available enterprise systems, comparing requirements with software functions and similar company profiles.</div>
                </div>

                <div className='max-md:hidden absolute -left-[100px] top-[20px] text-primary2'>
                    <svg width="613" height="316" viewBox="0 0 613 316" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M73.5798 3C43.2464 13.6667 -12.5202 49.5 7.07978 107.5C31.5798 180 177.58 230.5 276.58 221.5C375.58 212.5 548.08 241.5 609.58 312.5" stroke="currentColor" stroke-width="6" stroke-linecap="round" stroke-dasharray="12 12"/>
                    </svg>
                </div>

                <div className='max-md:hidden absolute text-primary -right-[100px] -top-[200px]'>
                    <div className='relative w-[500px] h-[500px]'>

                        <svg className="w-full h-full opacity-10 absolute z-[0]" viewBox="0 0 308 295" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M231.541 39.4029C265.681 62.1345 296.434 90.6138 304.507 130.861C313.368 175.038 306.02 223.28 274.968 255.893C243.518 288.922 195.528 298.7 150.336 292.777C106.863 287.08 70.7737 261.066 44.7652 225.724C16.0689 186.729 -10.1468 140.151 3.91078 93.8047C18.3226 46.2904 64.1073 14.4431 112.347 2.9143C154.864 -7.24694 195.143 15.1676 231.541 39.4029Z" fill="currentColor"/>
                        </svg>

                        <div className='absolute left-0 top-0 z-[1] w-[500px] h-[500px]'>
                            <TiltBox>
                                {/* <div style={{animation: "spin 30s linear infinite"}} className='absolute animate-spin top-[30%] left-[30%] w-[250px] h-[250px]'><GearIcon /></div>
                                <div style={{animation: "spin 30s linear reverse infinite"}} className='absolute top-[10%] left-[10%] w-[200px] h-[200px]'><GearIcon /></div> */}
                                <img src="/img/product_pictures/tilt_matchingengine/matchingengine_demopage.png" alt="" style={{transform: "translateZ(40px)"}} className="absolute w-[70%] " /> 
                                <img src="/img/product_pictures/tilt_matchingengine/matchingengine_zoom.png" alt="" style={{transform: "translateZ(100px)"}} className="absolute w-[40%] top-[25%] left-[15%]" />

                            </TiltBox>
                        </div>
                    </div>

                </div>
                
            </div>

            
            <div className='relative flex w-full justify-end lg:h-[406px]'>

                <div className='flex flex-col lg:w-[500px]'>
                    <div className='w-12 h-12 flex items-center justify-center bg-primary rounded-full text-white text-2xl text-center font-bold'>3</div>
                    <div className='text-2xl font-bold mt-2'>Receive your perfect enterprise system match</div>
                    <div className='text-md mt-2'>Receive a customized shortlist of enterprise system solutions with detailed comparisons.</div>
                </div>

                <div className='max-md:hidden absolute text-primary -left-[100px] -top-[00px]'>
                    <div className='relative w-[500px] h-[500px]'>

                        <svg className="w-full h-full rotate-45 scale-x-[-1] opacity-10 absolute z-[0]" viewBox="0 0 326 311" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M242.877 10.3309C268.88 27.4086 266.876 65.6702 281.003 93.3604C293.753 118.35 316.545 137.474 321.802 165.023C327.781 196.353 329.465 231.29 312.481 258.301C295.351 285.545 263.28 300.638 231.913 307.981C203.259 314.689 174.084 306.287 146.077 297.256C121.312 289.27 101.8 272.994 79.8973 258.962C52.996 241.726 13.6397 235.434 2.7509 205.428C-8.05062 175.664 15.6044 145.002 27.9611 115.846C39.2557 89.1969 49.8675 61.9128 71.6608 42.837C93.8549 23.4102 122.115 13.5575 151.11 8.05256C181.792 2.22763 216.785 -6.80538 242.877 10.3309Z" fill="currentColor"/>
                        </svg>

                        <div className='absolute left-0 top-0 z-[1] w-[500px] h-[500px]'>
                            <TiltBox>
                                {/* <img src="/img/tilt_matchingengine/matchingengine_page.png" alt="" style={{transform: "translateZ(0px)"}} className="absolute w-[70%]" /> */}
                                <img src="/img/product_pictures/tilt_matchingengine/matchingengine_results.png" alt="" style={{transform: "translateZ(0px)"}} className="absolute w-[70%]" />
                                <img src="/img/product_pictures/tilt_matchingengine/matchingengine_circles.png" alt="" style={{transform: "translateZ(45px)"}} className="absolute w-[25%] top-[50%] left-[55%]" />
                            </TiltBox>
                        </div>
                    </div>

                </div>

            </div>

        </div>
  )
}

export default MatchingEngineFlow