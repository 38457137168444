import React from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom';
import { parseDate } from '../../../utils/dateParser';
import NewsletterForm from '../../../components/NewsletterForm';

const BlogOverview = () => {
    const {articles} = useOutletContext();
    const navigate = useNavigate();
  return (
    <div className='md:w-[1000px] mx-auto mt-16 pb-16 mb-32'>
        <div className='w-full flex flex-col items-center'>
            <h1 className='text-4xl text-center font-bold'>Stay up to date</h1>
            <p className='text-md text-center text-gray-400 w-2/3'>Learn more about the latest developments, releases and features of find-your-SOFTWARE or benefit from relevant market insights and expert knowledge.</p>
        </div>

        <div className='mt-16 grid lg:grid-cols-3 grid-cols-1 max-md:px-4 gap-10'>
        {
            (typeof articles === "undefined") ? <div>Loading...</div> : (
                <>
                    {
                        articles.map(article => (
                            <div key={article.id} className='bg-white cursor-pointer group' onClick={() => navigate("article?articleId=" + String(article.id))}>
                                { article.attributes.cover.data == null ? <div className='h-48 w-full rounded-t-lg bg-gray-300'></div> : <img className='rounded-t-lg bg-gray-300 h-48 w-full object-cover object-top group-hover:brightness-125 transitions-all duration-1000' src={`${process.env.REACT_APP_HCMS_URL}${article.attributes.cover.data.attributes.url}`} />}
                                <h3 className='mt-4 text-sm text-gray-400'>{parseDate(article.attributes.updatedAt)}</h3>
                                <h3 className='text-xl font-semibold line-clamp-2'>{article.attributes.title}</h3>
                                <p className='mt-2 text-md line-clamp-4 text-gray-400'>{article.attributes.content.map(block => block.children[0].text)}</p>
                                
                                <button className="mt-2 text-base font-medium text-white items-center group transition-all focus:ring-4 focus:outline-none rounded-lg text-center" type="button" >
                                    <div className="flex w-full text-lg font-semibold text-primary group-hover:text-primary3 transition-all">
                                    <p className='underline underline-offset-4 group-hover:underline-offset-8 transition-all'>Learn more</p>
                                    <p className='translate-x-2 group-hover:translate-x-4 transition-all'>→</p>
                                    </div>
                                </button>
                            </div>
                        ))
                    }
                </>
            )
        }
        </div>

        <div className='mt-32 lg:w-1/2 px-8 mx-auto'>
            <h3 className='text-2xl font-semibold text-primary mb-2'>Join the newsletter</h3>
            <NewsletterForm />
        </div>

    </div>
  )
}

export default BlogOverview