import React from 'react'
import { CheckMark } from '../../01_Homepage/Features/ProductSection'
import { useNavigate } from 'react-router-dom';

const ForVendorsHeader = ({inOverviewPage}) => {
    const navigate = useNavigate();
  return (
    <div className={`${inOverviewPage ? "bg-gray-100" : ""}`}>
        <div className='lg:flex items-center lg:space-x-16 px-16 py-16 px-4'>

            <div className='lg:w-7/12 w-full lg:p-8 mb-16'>
                <img src="/img/product_pictures/screens_selectionportal/vendor_1.png" alt="logo" className="cursor-pointer transition-all duration-500 hover:scale-105 " />
            </div>

            <div className='lg:w-4/12 '>
            {inOverviewPage ? <p className='text-gray-800 text-sm uppercase font-semibold'>For vendors</p> : <></>}
            <h2 className='lg:text-4xl text-2xl font-bold mb-8'>Increase your market presence and acquire qualified sales leads</h2>
            <p className='mb-4 text-gray-700'>find-your-SOFTWARE gives you visibility in the market by listing you for free and matching you with potential customers. By analyzing potential customers with AI, we can suggest sales leads that really suit you and are enriched with high-quality details so that you can maximize your conversion success.</p>
            
            <ul class="space-y-4 text-left font-semibold text-gray-700">
                <li class="flex items-center space-x-3 rtl:space-x-reverse">
                    <CheckMark />
                    <span>Visibility on the market and with niche customers</span>
                </li>
                <li class="flex items-center space-x-3 rtl:space-x-reverse">
                    <CheckMark />
                    <span>Highly qualified sales leads through AI </span>
                </li>
                <li class="flex items-center space-x-3 rtl:space-x-reverse">
                    <CheckMark />
                    <span>Simplified interaction and communication with customers</span>
                </li>
            </ul>

            {inOverviewPage ? 
                <button  onClick={() => navigate("/solution/for-vendors")} className="mt-8 h-16 text-base font-medium text-white items-center group transition-all focus:ring-4 focus:outline-none rounded-lg text-center" type="button" >
                <div className="flex w-full text-lg font-semibold text-primary group-hover:text-primary3 transition-all">
                    <p className='underline underline-offset-4 group-hover:underline-offset-8 transition-all'>Learn more</p>
                    <p className='translate-x-2 group-hover:translate-x-4 transition-all'>→</p>
                </div>
                </button>
             : <></>}

        </div>
          

    </div>
        
        {/* <div className='lg:w-8/12 w-full max-md:hidden'>
        <img src="/img/matching_engine.png" alt="logo" className="cursor-pointer transition-all duration-500 hover:scale-105 " />
        </div> */}

    </div>
  )
}

export default ForVendorsHeader