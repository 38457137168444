import React from 'react'
import BookADemo from '../../../components/BookADemo';
import ForConsultantsHeader from './ForConsultantsHeader';
import { useNavigate } from 'react-router-dom';

const ForConsultantsPage = () => {
  const navigate = useNavigate();
  return (
    <div className="mb-2 text-gray-700 flex flex-col items-center justify-center relative w-full justify-center max-md:h-fit ">

      <div className='max-md:px-8'>
        <ForConsultantsHeader />
      </div>
      

      <div className='grid lg:grid-cols-2 lg:grid-rows-2 lg:my-4 py-32 bg-gray-100 w-full lg:px-64 px-8 gap-16'>
            
        <div className='grow flex flex-col'>
          <div className='grow'>
            <h2 className='text-4xl font-bold mb-8'>Market Analyses</h2>
            <p className='mb-4 text-gray-700'>Use find-your-SOFTWARE to stay up to date with the enterprise systems market and provide your customers with recommendations for shortlists and potential systems and vendors at the touch of a button. As a consultant, you receive the results of the find-your-SOFTWARE matching engine, as well as in-depth analyses, such as individual AI-generated company analyses and requirements catalogs, which you can use for working with your customers.</p>
          </div>

          <button  onClick={() => navigate("/product/matching-engine")} className="mt-8 h-16 text-base font-medium text-white items-center group transition-all focus:ring-4 focus:outline-none rounded-lg text-center" type="button" >
            <div className="flex w-full text-lg font-semibold text-primary group-hover:text-primary3 transition-all">
                <p className='underline underline-offset-4 group-hover:underline-offset-8 transition-all'>Learn more</p>
                <p className='translate-x-2 group-hover:translate-x-4 transition-all'>→</p>
            </div>
          </button>

        </div>
        
        <div className='grow flex flex-col'>
          <div className='grow'>
            <h2 className='lg:text-4xl text-2xl font-bold mb-8'>Process Efficiencies</h2>
            <p className='mb-4 text-gray-700'>As a consultant, you can use the find-your-SOFTWARE Selection Portal to carry out selection projects for customers in a collaborative and efficient manner - in all phases of software selection. You receive special analyses, e.g. on the quality and progress of the selection, to ensure the success of the project for your customer. Extensive configuration options, e.g. regarding the visibility of content and role management for key users and project participants, allow you to control the selection and ensure that the project content is only used in the interests of your customer.</p>
          </div>

          <button  onClick={() => navigate("/product/selection-portal")} className="mt-8 h-16 text-base font-medium text-white items-center group transition-all focus:ring-4 focus:outline-none rounded-lg text-center" type="button" >
            <div className="flex w-full text-lg font-semibold text-primary group-hover:text-primary3 transition-all">
                <p className='underline underline-offset-4 group-hover:underline-offset-8 transition-all'>Learn more</p>
                <p className='translate-x-2 group-hover:translate-x-4 transition-all'>→</p>
            </div>
          </button>
          
        </div>
        
        <div className='grow flex flex-col'>
          <div className='grow'>
            <h2 className='lg:text-4xl text-2xl font-bold mb-8'>Cross-Project Synergies</h2>
            <p className='mb-4 text-gray-700'>Benefit from the knowledge you have gained during previous projects by saving (anonymized) templates, e.g. of requirements catalogs, and retrieving them as needed in ongoing projects. In this way, you use find-your-SOFTWARE as a central knowledge base for your software selection projects and enable team members to build on existing knowledge.</p>
          </div>
          
          <button  onClick={() => navigate("/product/selection-portal")} className="mt-8 h-16 text-base font-medium text-white items-center group transition-all focus:ring-4 focus:outline-none rounded-lg text-center" type="button" >
            <div className="flex w-full text-lg font-semibold text-primary group-hover:text-primary3 transition-all">
                <p className='underline underline-offset-4 group-hover:underline-offset-8 transition-all'>Learn more</p>
                <p className='translate-x-2 group-hover:translate-x-4 transition-all'>→</p>
            </div>
          </button>
          
        </div>
        
        <div className='grow flex flex-col'>
          <div className='grow'>
            <h2 className='lg:text-4xl text-2xl font-bold mb-8'>Sales Leads</h2>
            <p className='mb-4 text-gray-700'>Offer your consulting services on find-your-SOFTWARE so that software seekers can seek your support and guidance. This efficiently increases your visibility and allows you to find customers who match your expertise. Customer acquisition and project implementation are carried out efficiently on a collaborative platform.</p>
          </div>
          
          <button  onClick={() => navigate("/solution/for-vendors")} className="mt-8 h-16 text-base font-medium text-white items-center group transition-all focus:ring-4 focus:outline-none rounded-lg text-center" type="button" >
            <div className="flex w-full text-lg font-semibold text-primary group-hover:text-primary3 transition-all">
                <p className='underline underline-offset-4 group-hover:underline-offset-8 transition-all'>Learn more</p>
                <p className='translate-x-2 group-hover:translate-x-4 transition-all'>→</p>
            </div>
          </button>
          
        </div>

      </div>

      {/* Say goodbye to confusing Excel lists and sluggish e-mail traffic and use find-your-SOFTWARE for your projects. */}

      <div className='w-full h-fit lg:px-64 my-32 px-4'>
          <div className='border rounded-[30px] bg-opacity-90 shadow-lg p-8'>
              <BookADemo />
          </div>
      </div>

    </div>
  )
}

export default ForConsultantsPage