import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import CalComWidget from './CalComWidget'

const BookADemo = () => {
    const [consentGiven, setConsentGiven] = useState(false);

    const handleGiveConsent = () => {
        setConsentGiven(true);
    }

    useEffect(() => {
        const head = document.querySelector('head');
        const script = document.createElement('script');
        script.setAttribute('src', '/scripts/calendry/widget.js');
        head.appendChild(script);
    }, [consentGiven])

    return (
        <div className='flex w-full justify-center items-start lg:py-16'>
            <div className='max-md:hidden h-full w-1/2'>
                <div className="flex-col justify-center px-12">

                    <h1 className='text-4xl text-center font-bold mb-8'>Book a free demo</h1>
            
                    <div className="relative group cursor-pointer mx-auto w-full md:w-72 md:h-72 flex items-end">
                        <div className="absolute h-44 group-hover:h-72 transition-all duration-500 w-full bg-[#f7ce46] rounded-lg"></div>
                        <img className={`object-cover rotate-0 w-full grayscale transition-all duration-500 brightness-125 group-hover:brightness-150 rounded-b-lg`} src={"/img/team/mp.png"} alt="bb" />
                    </div>

                    <p className="mt-2 text-center text-lg font-semibold text-gray-500 transition-all duration-500 group-hover:text-[#f7ce46]">Dr. Marcel Panzer</p>
                    <p className="text-center italic text-gray-500">Co-Founder / Deep Learning Engineer</p>
                    
                    <p className="text-center text-xl font-semibold mt-12 text-gray-500">"I look forward to getting to know you and introducing you to find-your-SOFTWARE."</p>

                </div>
            </div>
            <div className='h-full w-1/2 max-md:w-full lg:border-l lg:px-12 flex flex-col items-center'>
                <h1 className='text-4xl text-center font-bold mb-8'>Schedule a meeting</h1>

                {
                    !consentGiven ? (
                        <div onClick={() => handleGiveConsent()} className="overflow-y-auto cursor-pointer h-[400px] md:h-[500px] xl:h-[700px] flex items-center justify-center bg-gray-300 rounded-lg">
                            <div className="flex flex-col w-full">
                                
                                <svg className="mx-auto max-md:w-5 max-md:h-5 w-10 h-10 text-gray-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                </svg>

                                <p className="max-md:text-sm text-center text-gray-700 p-6">
                                    Before we display the form, we need your consent. Loading the form will transmit information to Cal.com, including your IP address, and cookies will be stored on your device. Please click here to give your consent and view the form. More details can be found in our privacy policy.
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div id="schedule_form" className='w-full'>
                            
                            {/* <div 
                                className="calendly-inline-widget w-full h-[500px]"
                                data-url="https://calendly.com/clip-hinweise0z/30min?hide_event_type_details=1&hide_gdpr_banner=1"
                            /> */}
                            <CalComWidget />

                        </div>
                    )
                }
                
                <div className='flex w-full justify-start my-8'>
                    <div className=''>

                    <a href="https://cal.com/sectorlens/30min" target="_blank" className="cursor-pointer text-base font-medium text-white items-center group transition-all focus:ring-4 focus:outline-none rounded-lg text-center" type="button" >
                        <div className="text-left flex w-full text-lg font-semibold text-primary group-hover:text-primary3 transition-all">
                            <p className='underline underline-offset-4 group-hover:underline-offset-8 transition-all'>Or book via Cal.com website</p>
                            <p className='translate-x-2 group-hover:translate-x-4 transition-all'>→</p>
                        </div>
                    </a>

                    <a href="mailto:hi@sectorlens.de" className="mt-4 cursor-pointer text-base font-medium text-white items-center group transition-all focus:ring-4 focus:outline-none rounded-lg text-center" type="button" >
                        <div className="flex w-full text-lg font-semibold text-primary group-hover:text-primary3 transition-all">
                            <p className='underline underline-offset-4 group-hover:underline-offset-8 transition-all'>Or write us a message</p>
                            <p className='translate-x-2 group-hover:translate-x-4 transition-all'>→</p>
                        </div>
                    </a>
                    
                    <Link to="/contact" className="mt-4 cursor-pointer text-base font-medium text-white items-center group transition-all focus:ring-4 focus:outline-none rounded-lg text-center" type="button" >
                        <div className="flex w-full text-lg font-semibold text-primary group-hover:text-primary3 transition-all">
                            <p className='underline underline-offset-4 group-hover:underline-offset-8 transition-all'>Or join our newsletter</p>
                            <p className='translate-x-2 group-hover:translate-x-4 transition-all'>→</p>
                        </div>
                    </Link>

                    </div>
                </div>

                <p className='text-sm text-gray-400 inline'>Booking appointments via Cal.com requires the use of external services or visits to external websites. For more information, see our <Link to={"/privacy-policy"} className='underline underline-offset-2 hover:underline-offset-4 transition-all duration-500'>privacy policy</Link>.</p>
            </div>
        </div>
  )
}

export default BookADemo